<template>
  <div class="settings columns container">
    <setting-intro-banner
      :header-image="inbox.avatarUrl"
      :header-title="inboxName"
    >
      <woot-tabs
        class="settings--tabs"
        :index="selectedTabIndex"
        :border="false"
        @change="onTabChange"
      >
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>
    </setting-intro-banner>

    <div v-if="selectedTabKey === 'inbox_settings'" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_SUB_TEXT')"
      >
        <woot-avatar-uploader
          :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL')"
          :src="avatarUrl"
          class="settings-item"
          delete-avatar
          @change="handleImageUpload"
          @onAvatarDelete="handleAvatarDelete"
        />
        <woot-input
          v-model.trim="selectedInboxName"
          class="medium-9 columns settings-item"
          :class="{ error: $v.selectedInboxName.$error }"
          :label="inboxNameLabel"
          :placeholder="inboxNamePlaceHolder"
          :error="
            $v.selectedInboxName.$error
              ? $t('INBOX_MGMT.ADD.CHANNEL_NAME.ERROR')
              : ''
          "
          @blur="$v.selectedInboxName.$touch"
        />
        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="selectedInboxEmail"
          class="medium-9 columns settings-item"
          :class="{ error: $v.selectedInboxEmail.$error }"
          :label="$t('INBOX_MGMT.ADD.CHANNEL_EMAIL.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.CHANNEL_EMAIL.PLACEHOLDER')"
          @input="$v.selectedInboxEmail.$touch"
        />
        <label
          v-if="isATwitterInbox"
          for="toggle-business-hours"
          class="toggle-input-wrap"
        >
          <input
            v-model="tweetsEnabled"
            type="checkbox"
            name="toggle-business-hours"
          />
          {{ $t('INBOX_MGMT.ADD.TWITTER.TWEETS.ENABLE') }}
        </label>
        <woot-input
          v-if="(isAPIInbox && !isCustomWebhook) || isCallpickerUser"
          v-model.trim="webhookUrl"
          class="medium-9 columns settings-item"
          :class="{ error: $v.webhookUrl.$error }"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.LABEL')
          "
          :placeholder="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.PLACEHOLDER')
          "
          :error="
            $v.webhookUrl.$error
              ? $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.ERROR')
              : ''
          "
          @blur="$v.webhookUrl.$touch"
        />
        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWebsiteUrl"
          class="medium-9 columns settings-item"
          :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.PLACEHOLDER')
          "
        />
        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWelcomeTitle"
          class="medium-9 columns settings-item"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.LABEL')
          "
          :placeholder="
            $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.PLACEHOLDER'
            )
          "
        />

        <woot-input
          v-if="isAWebWidgetInbox"
          v-model.trim="channelWelcomeTagline"
          class="medium-9 columns settings-item"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL')
          "
          :placeholder="
            $t(
              'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
            )
          "
        />

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_COLOR.LABEL') }}
          <woot-color-picker v-model="inbox.widget_color" />
        </label>

        <label v-if="isAWhatsAppChannel" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL') }}
          <input v-model="whatsAppAPIProviderName" type="text" disabled />
        </label>

        <label class="medium-9 columns settings-item" v-if="!isMercadoLibreInbox">
          {{
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.LABEL')
          }}
          <select v-model="greetingEnabled">
            <option :value="true">
              {{
                $t(
                  'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.ENABLED'
                )
              }}
            </option>
            <option :value="false">
              {{
                $t(
                  'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.DISABLED'
                )
              }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.HELP_TEXT'
              )
            }}
          </p>
        </label>
        <div v-if="greetingEnabled && !isMercadoLibreInbox" class="settings-item">
          <greetings-editor
            v-model.trim="greetingMessage"
            :label="
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL'
              )
            "
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.PLACEHOLDER'
              )
            "
            :richtext="!textAreaChannels"
          />
        </div>
        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.TITLE') }}
          <select v-model="replyTime">
            <option key="in_a_few_minutes" value="in_a_few_minutes">
              {{
                $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_MINUTES')
              }}
            </option>
            <option key="in_a_few_hours" value="in_a_few_hours">
              {{
                $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_HOURS')
              }}
            </option>
            <option key="in_a_day" value="in_a_day">
              {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_DAY') }}
            </option>
          </select>

          <p class="help-text">
            {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.HELP_TEXT') }}
          </p>
        </label>

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX') }}
          <select v-model="emailCollectEnabled">
            <option :value="true">
              {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.ENABLED') }}
            </option>
            <option :value="false">
              {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX_SUB_TEXT')
            }}
          </p>
        </label>

        <label class="medium-9 columns settings-item" v-if="!isMercadoLibreInbox">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT') }}
          <select v-model="csatSurveyEnabled">
            <option :value="true">
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.ENABLED') }}
            </option>
            <option :value="false">
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT_SUB_TEXT') }}
          </p>
        </label>
        <label v-if="isAFacebookInbox" class="medium-9 columns settings-item">
          <p v-if="inbox.has_instagram">
            {{ $t('INBOX_MGMT.DETAILS.HAS_INSTAGRAM') }}
          </p>
          <p v-if="!inbox.has_instagram">
            {{ $t('INBOX_MGMT.DETAILS.HAS_NOT_INSTAGRAM') }}
          </p>
        </label>
        <div v-if="isAFacebookInbox && inbox.has_instagram" class="medium-9">
          <div class="card-container">
            <div class="card-content">
              <div class="flex-container">
                <div class="double-profile-image">
                  <img class="top-left-image" :src="avatarUrlIntagram" alt="Top Left Image">
                  <img class="bottom-right-image" src="/assets/images/dashboard/channels/instagram.png" alt="Bottom Right Image">
                </div>
                <div class="space-left">
                  <label class="card-title title-size"><p>{{ instagramUsername }}</p></label>
                  <label class="card-subtitle title-size"><p>{{ instagramName }}</p></label>
                </div>
              </div>

              <div class="card-footer">
                <label class="card-label">
                 <p>{{ $t('INBOX_MGMT.DETAILS.POST') }}: {{ posts }}</p>
                </label>
                <label class="card-label">
                 <p>{{ $t('INBOX_MGMT.DETAILS.FOLLOWERS') }}: {{ followers }}</p>
                </label>
                <label class="card-label">
                 <p>{{ $t('INBOX_MGMT.DETAILS.FOLLOWING') }}: {{ following }}</p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED') }}
          <select v-model="allowMessagesAfterResolved">
            <option :value="true">
              {{ $t('INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.ENABLED') }}
            </option>
            <option :value="false">
              {{ $t('INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED_SUB_TEXT'
              )
            }}
          </p>
        </label>

        <label v-if="isAWebWidgetInbox" class="medium-9 columns settings-item">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL') }}
          <select v-model="continuityViaEmail">
            <option :value="true">
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.ENABLED') }}
            </option>
            <option :value="false">
              {{ $t('INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL_SUB_TEXT'
              )
            }}
          </p>
        </label>
        <!-- div class="medium-9 settings-item settings-item">
          <label>
            {{ $t('INBOX_MGMT.HELP_CENTER.LABEL') }}
          </label>
          <select v-model="selectedPortalSlug" class="filter__question">
            <option value="">
              {{ $t('INBOX_MGMT.HELP_CENTER.PLACEHOLDER') }}
            </option>
            <option v-for="p in portals" :key="p.slug" :value="p.slug">
              {{ p.name }}
            </option>
          </select>
          <p class="help-text">
            {{ $t('INBOX_MGMT.HELP_CENTER.SUB_TEXT') }}
          </p>
        </div -->
        <label
          v-if="canLocktoSingleConversation"
          class="medium-9 columns settings-item"
        >
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.LOCK_TO_SINGLE_CONVERSATION') }}
          <select v-model="locktoSingleConversation">
            <option :value="true">
              {{ $t('INBOX_MGMT.EDIT.LOCK_TO_SINGLE_CONVERSATION.ENABLED') }}
            </option>
            <option :value="false">
              {{ $t('INBOX_MGMT.EDIT.LOCK_TO_SINGLE_CONVERSATION.DISABLED') }}
            </option>
          </select>
          <p class="help-text">
            {{
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.LOCK_TO_SINGLE_CONVERSATION_SUB_TEXT'
              )
            }}
          </p>
        </label>

        <label v-if="isAWebWidgetInbox">
          {{ $t('INBOX_MGMT.FEATURES.LABEL') }}
        </label>
        <div
          v-if="isAWebWidgetInbox"
          class="widget--feature-flag settings-item"
        >
          <input
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="attachments"
            @input="handleFeatureFlag"
          />
          <label for="attachments">
            {{ $t('INBOX_MGMT.FEATURES.DISPLAY_FILE_PICKER') }}
          </label>
        </div>
        <div v-if="isAWebWidgetInbox" class="settings-item settings-item">
          <input
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="emoji_picker"
            @input="handleFeatureFlag"
          />
          <label for="emoji_picker">
            {{ $t('INBOX_MGMT.FEATURES.DISPLAY_EMOJI_PICKER') }}
          </label>
        </div>
        <div v-if="isAWebWidgetInbox" class="settings-item settings-item">
          <input
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="end_conversation"
            @input="handleFeatureFlag"
          />
          <label for="end_conversation">
            {{ $t('INBOX_MGMT.FEATURES.ALLOW_END_CONVERSATION') }}
          </label>
        </div>
        <div v-if="isAWebWidgetInbox" class="settings-item settings-item">
          <input
            v-model="selectedFeatureFlags"
            type="checkbox"
            value="use_inbox_avatar_for_bot"
            @input="handleFeatureFlag"
          />
          <label for="emoji_picker">
            {{ $t('INBOX_MGMT.FEATURES.USE_INBOX_AVATAR_FOR_BOT') }}
          </label>
        </div>

        <div v-if="(isWhatsAppInbox || isWhatsBailInbox)" class="medium-9 columns settings-item">

          <woot-input
            v-if="!!phone_number"
            v-model="phone_number"
            :readonly="true"
            class="medium-9 columns settings-item"
            :label="$t('INBOX_MGMT.ADD.WHATAPPWEB.PHONE_NUMBER')"
            :placeholder="$t(
                'INBOX_MGMT.ADD.WHATAPPWEB.PHONE_NUMBER'
              )
              "
          />

          <label v-if="isCallpickerUser && isWhatsAppInbox"><p>{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.CHANGE_INBOX.LABEL') }}</p></label>
          <p v-if="isCallpickerUser && isWhatsAppInbox"><woot-submit-button
            type="submit"
            :disabled="loading"
            :button-text="$t('INBOX_MGMT.ADD.WHATAPPWEB.CHANGE_INBOX.BUTTON_TEXT_WHATSBAIL')"
            :loading="loading"
            @click="changeInboxWhatsAppType"
          /></p>


          <label>
            <p>{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.QR_TITLE') }}</p>

            <!-- 0 - Verifica tu conexión -->
            <p
              v-if="qrStatus == 0 "
              class="help-text"
            >{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.SECTION_TEXT.CLICK_BUTTON') }}</p>

            <!-- 1 - Conexión exitosa -->
            <p
              v-if="qrStatus == 1"
              class="help-text"
            >{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.SECTION_TEXT.RELOAD_PAGE') }}</p>

            <!-- 2 - Conexión fallida, 3 - Conexión cerrada, 4 - QR Steps -->
            <p
              v-if="qrStatus == 2 || qrStatus == 3 || qrStatus == 4"
              class="help-text"
            >
              {{ $t('INBOX_MGMT.ADD.WHATAPPWEB.SECTION_TEXT.FOLLOW_STEPS') }}
            </p>
          </label>

          <woot-submit-button
            v-if="qrStatus === 0"
            type="submit"
            :disabled="loading"
            :button-text="$t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.CHECK') "
            :loading="loading"
            @click="processQRCode"
          />

          <AlertComponent
            v-if="qrStatus === 1"
            type="success"
            :title="$t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.SUCCESS.TITLE')"
          >
            <p>{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.SUCCESS.BODY') }}</p>
          </AlertComponent>

          <AlertComponent
            v-if="qrStatus === 2"
            type="danger"
            :title="$t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.FAILED.TITLE')"
          >
            <p class="mb-4">{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.FAILED.BODY') }}</p>
            <ol class="list-decimal">
              <li>
                {{ $t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.FAILED.ANDROID.FIRST_STEP_BEFORE_ICON') }}
                <fluent-icon
                  class="bg-white border border-gray-400 text-gray-700 rounded-md inline"
                  icon="more-vertical"
                />
                {{ $t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.FAILED.ANDROID.FIRST_STEP_AFTER_ICON') }}
              </li>
              <li>{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.FAILED.ANDROID.SECOND_STEP') }}</li>
              <li>{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.FAILED.ANDROID.THIRD_STEP') }}</li>
            </ol>
          </AlertComponent>


          <AlertComponent
            v-if="qrStatus === 3"
            type="success"
            :title="$t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.CLOSED.TITLE')"
          >
            <p>{{ $t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.CLOSED.BODY') }}</p>
          </AlertComponent>

          <woot-submit-button
            v-if="qrStatus === 2 || qrStatus === 3"
            type="submit"
            :disabled="false"
            :button-text="$t('INBOX_MGMT.ADD.WHATAPPWEB.CONNECTION_STATUS.RELINK')"
            :loading="false"
            @click="requestNewConnection"
          />

          <QRSteps
            v-if="qrStatus === 4"
            @retryQr="processQRCode"
            :qrLink="whatsappwebQR"
          />

          <!-- <img v-on:click="processQRCode" width="264px" height="264px" :src="whatsappwebQR" />  -->
        </div>


        <div v-if="isMercadoLibreInbox" class="medium-9 columns settings-item">
          <label>
            <p>{{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.RELINK') }}</p>
          </label>
          <p
              v-if="timeout && !success_login"
              class="help-text"
            >
              {{ $t('INBOX_MGMT.ADD.WHATAPPWEB.SECTION_TEXT.FOLLOW_STEPS') }}
          </p>

          <AlertComponent
            v-if="disconnected"
            type="danger"
            :title="$t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.FAILED.TITLE')"
          >
            <p class="mb-4">
              {{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.FAILED.DISCONNECT') }}
              {{ mercado_email }}.
            </p>

          </AlertComponent>

          <AlertComponent
            v-if="timeout && !success_login"
            type="danger"
            :title="$t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.FAILED.TITLE')"
          >
            <p class="mb-4">
              {{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.FAILED.CONFIG_BODY_ONE') }}
              {{ mercado_email }}
              {{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.FAILED.CONFIG_BODY_TWO') }}

            </p>

          </AlertComponent>

          <AlertComponent
            v-if="success_login"
            type="success"
            :title="$t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.SUCCESS.TITLE')"
          >
            <p>{{ $t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.SUCCESS.BODY') }}</p>
          </AlertComponent>


          <woot-submit-button
            type="submit"
            v-show="!success_login"
            :disabled="loading"
            :button-text="$t('INBOX_MGMT.ADD.MERCADOLIBRE.CONNECTION_STATUS.RELINK') "
            :loading="loading"
            @click="ML_Loggin"
          />
        </div>

        <div v-if="isFacebookCommentInbox" class="medium-9 columns settings-item">
          <label>
            <p>{{ $t('INBOX_MGMT.ADD.FACEBOOKCOMMENTS.CONNECTION_STATUS.RELINK') }}</p>
            <p>{{ $t('INBOX_MGMT.ADD.FACEBOOKCOMMENTS.CONNECTION_STATUS.RELINK_DESC') }} <b>{{selectedInboxName}}</b> {{ $t('INBOX_MGMT.ADD.FACEBOOKCOMMENTS.CONNECTION_STATUS.RELINK_DESC2') }}</p>
          </label>

          <woot-submit-button
            type="submit"
            v-show="!success_login"
            :disabled="loading"
            :button-text="$t('INBOX_MGMT.ADD.FACEBOOKCOMMENTS.CONNECTION_STATUS.RELINK_BUTTON')"
            :loading="loading"
            @click="redirectInbox"
          />
        </div>

        <woot-submit-button
          v-if="isAPIInbox"
          type="submit"
          :disabled="$v.webhookUrl.$invalid"
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="uiFlags.isUpdating"
          @click="updateInbox"
          style="margin-top: 10rem;"
          />
          <woot-submit-button
          v-else
          type="submit"
          :disabled="isAWebWidgetInbox&&$v.selectedInboxEmail.$invalid"
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="uiFlags.isUpdating"
          @click="updateInbox"
          style="margin-top: 10rem;"
        />
      </settings-section>
      <facebook-reauthorize v-if="isAFacebookInbox" :inbox-id="inbox.id" />
    </div>

    <div v-if="selectedTabKey === 'collaborators'" class="settings--content">
      <collaborators-page :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'configuration'">
      <configuration-page :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'preChatForm'">
      <pre-chat-form-settings :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'businesshours'">
      <weekly-availability :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'widgetBuilder'">
      <widget-builder :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'botConfiguration'">
      <bot-configuration :inbox="inbox" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import { shouldBeUrl } from 'shared/helpers/Validators';
import configMixin from 'shared/mixins/configMixin';
import alertMixin from 'shared/mixins/alertMixin';
import SettingIntroBanner from 'dashboard/components/widgets/SettingIntroBanner';
import SettingsSection from '../../../../components/SettingsSection';
import AlertComponent from '../../../../components/Alert';
import QRSteps from './components/QRSteps';
import inboxMixin from 'shared/mixins/inboxMixin';
import FacebookReauthorize from './facebook/Reauthorize';
import PreChatFormSettings from './PreChatForm/Settings';
import WeeklyAvailability from './components/WeeklyAvailability';
import GreetingsEditor from 'shared/components/GreetingsEditor';
import ConfigurationPage from './settingsPage/ConfigurationPage';
import CollaboratorsPage from './settingsPage/CollaboratorsPage';
import WidgetBuilder from './WidgetBuilder';
import BotConfiguration from './components/BotConfiguration';
import { FEATURE_FLAGS } from '../../../../featureFlags';
import refresh_wa from 'dashboard/assets/images/channels/refresh_wa.png'
//import loading from 'dashboard/assets/images/channels/loader.gif'
//import sucess_wa from 'dashboard/assets/images/channels/wa_success.png'
//import check_wa from 'dashboard/assets/images/channels/wa_check_status.png'
import SocketIO from 'socket.io-client'
const axios = require('axios');

export default {
  components: {
    BotConfiguration,
    CollaboratorsPage,
    ConfigurationPage,
    FacebookReauthorize,
    GreetingsEditor,
    PreChatFormSettings,
    SettingIntroBanner,
    SettingsSection,
    AlertComponent,
    QRSteps,
    FacebookReauthorize,
    PreChatFormSettings,
    WeeklyAvailability,
    WidgetBuilder,
  },
  mixins: [alertMixin, configMixin, inboxMixin],
  data() {
    return {
      avatarFile: null,
      avatarUrl: '',
      avatarUrlIntagram: '',
      posts: '',
      followers: '',
      following: '',
      instagramName: '',
      instagramUsername: '',
      greetingEnabled: true,
      tweetsEnabled: true,
      greetingMessage: '',
      emailCollectEnabled: false,
      csatSurveyEnabled: false,
      locktoSingleConversation: false,
      allowMessagesAfterResolved: true,
      continuityViaEmail: true,
      selectedInboxName: '',
      selectedInboxEmail: '',
      channelWebsiteUrl: '',
      webhookUrl: '',
      settings: null,
      channelWelcomeTitle: '',
      channelWelcomeTagline: '',
      selectedFeatureFlags: [],
      replyTime: '',
      selectedTabIndex: 0,
      selectedPortalSlug: '',
      socket: null,
      canReloadQR : true,
      otherClients : process.env.WHATS_APP_WEB_API_OTHER_CLIENTS,
      whatsappwebQR : "",
      refresh_wa: refresh_wa,
      loading: false,
      isRequestingNewConnection: false,
      //sucess_wa: sucess_wa,
      qrStatus: 0,
      phone_number: null,
      ioChanelListen:false,
      ventana:null,
      timeout: false,
      timer: null,
      success_login:false,
      mercado_email: "",
      disconnected: false
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      uiFlags: 'inboxes/getUIFlags',
      portals: 'portals/allPortals',
    }),
    selectedTabKey() {
      return this.tabs[this.selectedTabIndex]?.key;
    },
    isCustomWebhook() {
      return ((this.isMercadoLibreInbox)||(this.isWhatsAppGupshupInbox)||(this.isWhatsBailInbox)||(this.isWhatsAppInbox));
    },
    isCallpickerUser() {
      if (this.currentUser && this.currentUser.email) {
        const regex = /^[^\s@]+@callpicker\.com$/i;
        return regex.test(this.currentUser.email);
      }

      return false
    },
    whatsAppAPIProviderName() {
      if (this.isAWhatsAppCloudChannel) {
        return this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD');
      }
      if (this.is360DialogWhatsAppChannel) {
        return this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.360_DIALOG');
      }
      if (this.isATwilioWhatsAppChannel) {
        return this.$t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO');
      }
      return '';
    },
    tabs() {
      let visibleToAllChannelTabs = [
        {
          key: 'inbox_settings',
          name: this.$t('INBOX_MGMT.TABS.SETTINGS'),
        },
        {
          key: 'collaborators',
          name: this.$t('INBOX_MGMT.TABS.COLLABORATORS'),
        },
        {
          key: 'businesshours',
          name: this.$t('INBOX_MGMT.TABS.BUSINESS_HOURS'),
        },
      ];

      if (this.isAWebWidgetInbox) {
        visibleToAllChannelTabs = [
          ...visibleToAllChannelTabs,
          {
            key: 'preChatForm',
            name: this.$t('INBOX_MGMT.TABS.PRE_CHAT_FORM'),
          },
          {
            key: 'widgetBuilder',
            name: this.$t('INBOX_MGMT.TABS.WIDGET_BUILDER'),
          },
        ];
      }

      if (
        this.isATwilioChannel ||
        this.isALineChannel ||
        this.isAPIInbox ||
        (this.isAnEmailChannel && !this.inbox.provider) ||
        (this.isAnEmailChannel && this.inbox.provider === 'microsoft') ||
        this.isAWhatsAppChannel ||
        this.isAWebWidgetInbox
      ) {
        visibleToAllChannelTabs = [
          ...visibleToAllChannelTabs,
          {
            key: 'configuration',
            name: this.$t('INBOX_MGMT.TABS.CONFIGURATION'),
          },
        ];
      }

      if (
        this.isFeatureEnabledonAccount(
          this.accountId,
          FEATURE_FLAGS.AGENT_BOTS
        ) &&
        !(this.isAnEmailChannel || this.isATwitterInbox)
      ) {
        visibleToAllChannelTabs = [
          ...visibleToAllChannelTabs,
          {
            key: 'botConfiguration',
            name: this.$t('INBOX_MGMT.TABS.BOT_CONFIGURATION'),
          },
        ];
      }
      return visibleToAllChannelTabs;
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },

    inboxName() {
      if (this.isATwilioSMSChannel || this.isATwilioWhatsAppChannel) {
        return `${this.inbox.name} (${this.inbox.messaging_service_sid ||
          this.inbox.phone_number})`;
      }
      if (this.isAWhatsAppChannel) {
        return `${this.inbox.name} (${this.inbox.phone_number})`;
      }
      if (this.isAnEmailChannel) {
        return `${this.inbox.name} (${this.inbox.email})`;
      }
      return this.inbox.name;
    },
    canLocktoSingleConversation() {
      return this.isASmsInbox || (this.isAWhatsAppChannel && !this.isATwilioChannel);
    },
    inboxNameLabel() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.LABEL');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.LABEL');
    },
    inboxNamePlaceHolder() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.PLACEHOLDER');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.PLACEHOLDER');
    },
    textAreaChannels() {
      if (
        this.isATwilioChannel ||
        this.isATwitterInbox ||
        this.isAFacebookInbox
      )
        return true;
      return false;
    }
  },
  watch: {
    $route(to) {
      if (to.name === 'settings_inbox_show') {
        this.fetchInboxSettings();
      }
    },
  },
  mounted() {
    setTimeout(this.checkInbox, 500)
    this.checkInboxNumber()
  },
  async created() {
    await this.fetchInboxSettings();
    window.addEventListener("beforeunload", this.unclosed);
  },
  methods: {
    fetchPortals() {
      this.$store.dispatch('portals/index');
    },
    handleFeatureFlag(e) {
      this.selectedFeatureFlags = this.toggleInput(
        this.selectedFeatureFlags,
        e.target.value
      );
    },
    checkInboxNumber() {
      var self = this;
      console.log("phone_number");
      console.log(this.phone_number);
      console.log(((this.isWhatsAppGupshupInbox) || (this.isWhatsAppInbox)));
      console.log((!this.phone_number));

      if (((this.isWhatsAppGupshupInbox) || (this.isWhatsAppInbox)) && (!this.phone_number)) {
        var url = null;
        var is_second_server = (JSON.parse(self.otherClients).includes(self.accountId + "")) ? 1 : 0;
        if (this.isWhatsAppGupshupInbox) {
          url = process.env.CALLPICKER_API+"/chat/getInboxPhone?inbox_type=gupshup&inbox_id="+this.currentInboxId +"&is_second_server="+ is_second_server;
        } else if (this.isWhatsAppInbox) {
          url = process.env.CALLPICKER_API + "/chat/getInboxPhone?inbox_type=waqr&inbox_id=" + this.currentInboxId + "&is_second_server=" + is_second_server;
        }
        if (url == null) {
          return 1
        }

        axios.get(url)
          .then(res => {
            self.phone_number = res.data.payload.phone;
            self.settings.number = res.data.payload.phone;
          })
      }
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        const newSelectedFlags = selected.filter(flag => flag !== current);
        return newSelectedFlags;
      }
      return [...selected, current];
    },
    onTabChange(selectedTabIndex) {
      this.selectedTabIndex = selectedTabIndex;
    },
    async fetchInboxSettings() {
      this.socket?.removeAllListeners(`qr_data:${this.inbox.id}`);
      this.selectedTabIndex = 0;
      this.selectedAgents = [];
      this.$store.dispatch('agents/get');
      this.$store.dispatch('teams/get');
      this.$store.dispatch('labels/get');
      await this.$store.dispatch('inboxes/get').then(() => {
        this.avatarUrl = this.inbox.avatar_url;
        this.avatarUrlIntagram = (this.isAFacebookInbox && this.inbox.has_instagram) ? this.inbox.instagram_data.profile_picture_url : '';
        this.posts = (this.isAFacebookInbox && this.inbox.has_instagram) ? this.inbox.instagram_data.media_count : '';
        this.followers = (this.isAFacebookInbox && this.inbox.has_instagram) ? this.inbox.instagram_data.followers_count : '';
        this.following = (this.isAFacebookInbox && this.inbox.has_instagram) ? this.inbox.instagram_data.follows_count : '';
        this.instagramName = (this.isAFacebookInbox && this.inbox.has_instagram) ? this.inbox.instagram_data.name : '';
        this.instagramUsername = (this.isAFacebookInbox && this.inbox.has_instagram) ? this.inbox.instagram_data.username : '';
        this.selectedInboxName = this.inbox.name;
        this.selectedInboxEmail = this.inbox.email_address;
        this.webhookUrl = this.inbox.webhook_url;
        this.settings = this.inbox.settings;
        console.log(this.inbox.settings);
        this.greetingEnabled = this.inbox.greeting_enabled || false;
        this.tweetsEnabled = this.inbox.tweets_enabled || false;
        this.greetingMessage = this.inbox.greeting_message || '';
        this.emailCollectEnabled = this.inbox.enable_email_collect;
        this.csatSurveyEnabled = this.inbox.csat_survey_enabled;
        this.allowMessagesAfterResolved = this.inbox.allow_messages_after_resolved;
        this.continuityViaEmail = this.inbox.continuity_via_email;
        this.channelWebsiteUrl = this.inbox.website_url;
        this.channelWelcomeTitle = this.inbox.welcome_title;
        this.channelWelcomeTagline = this.inbox.welcome_tagline;
        this.selectedFeatureFlags = this.inbox.selected_feature_flags || [];
        this.replyTime = this.inbox.reply_time;
        this.locktoSingleConversation = this.inbox.lock_to_single_conversation;
        this.selectedPortalSlug = this.inbox.help_center
          ? this.inbox.help_center.slug
          : '';
        this.isRequestingNewConnection = false;
        this.qrStatus = 0;
        this.whatsappwebQR = "";
        this.loading = false;
        this.canReloadQR = true;
        this.socket = null;
        if(this.settings){
          if(this.settings.number){
            this.phone_number = this.settings.number;
          }else{
            this.phone_number = null;
          }
        }
      });
    },
    async updateInbox() {
      try {
        var payload = {
          id: this.currentInboxId,
          name: this.selectedInboxName,
          email_address: this.selectedInboxEmail,
          enable_email_collect: this.emailCollectEnabled,
          csat_survey_enabled: this.csatSurveyEnabled,
          allow_messages_after_resolved: this.allowMessagesAfterResolved,
          greeting_enabled: this.greetingEnabled,
          greeting_message: this.greetingMessage || '',
          portal_id: this.selectedPortalSlug
            ? this.portals.find(
                portal => portal.slug === this.selectedPortalSlug
              ).id
            : null,
          lock_to_single_conversation: this.locktoSingleConversation,
          channel: {
            widget_color: this.inbox.widget_color,
            website_url: this.channelWebsiteUrl,
            webhook_url: this.webhookUrl,
            welcome_title: this.channelWelcomeTitle || '',
            welcome_tagline: this.channelWelcomeTagline || '',
            selectedFeatureFlags: this.selectedFeatureFlags,
            reply_time: this.replyTime || 'in_a_few_minutes',
            tweets_enabled: this.tweetsEnabled,
            continuity_via_email: this.continuityViaEmail,
          },
        };
        if (this.avatarFile) {
          payload.avatar = this.avatarFile;
        }
        if (this.settings) {
          payload.settings = JSON.stringify(this.settings);
        }
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE')
        );
      }
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async handleAvatarDelete() {
      try {
        await this.$store.dispatch(
          'inboxes/deleteInboxAvatar',
          this.currentInboxId
        );
        this.avatarFile = null;
        this.avatarUrl = '';
        this.showAlert(this.$t('INBOX_MGMT.DELETE.API.AVATAR_SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('INBOX_MGMT.DELETE.API.AVATAR_ERROR_MESSAGE')
        );
      }
    },
    async createChannel() {
      var self = this;
      console.log("createChannel " + self.inbox.id);

      if(self.socket == null){
        console.log("Start Socket " + self.inbox.id);

        if (self.isWhatsBailInbox) {
          var socket_url = process.env.WHATSBAIL_WEB_WSS;
          console.log("Socket isWhatsBailInbox " + socket_url);
          if (JSON.parse(self.otherClients).includes(self.accountId + "")) {
            socket_url = process.env.WHATSBAIL_WEB_WSS;
          }
          self.socket = await SocketIO(socket_url, {
            transports: ['websocket']
          });
          const key = btoa(self.phone_number);
          self.socket.emit('register', self.phone_number);

          await self.socket.on(`status`, (data) => {
            if (data.key != key) {
              console.log("Skip event " + data.key);
              return;
            }
            self.chooseQrAction(data);
          });
        } else {
          var socket_url = process.env.WHATS_APP_WEB_WSS;
          console.log("Socket WhatsAPIqr " + socket_url);
          if (JSON.parse(self.otherClients).includes(self.accountId + "")) {
            socket_url = process.env.WHATS_APP_WEB_WSS_OTHER;
          }

          self.socket = await SocketIO(socket_url, {
            transports: ['websocket']
          })

          await self.socket.on(`qr_data:${self.inbox.id}`, (data) => {
            console.log(data);
            if (data.key != self.inbox.id) {
              console.log("Skip event " + data.key);
              return;
            }
            self.chooseQrAction(data);
          });
        }
      }
    },
    async chooseQrAction(data) {
      var self = this;
      switch (data.status) {
        case 'loading':
          self.printQR(data.qr);
          break;
        case 'logged':
          self.canReloadQR = false;
          self.loggedQR();
          break;
        case 'disconnected':
          console.log("disconnected");
          // Este if es porque si hay múltiples personas intentando generar un QR, a la persona
          // original se le mostrará el aviso de sesión cerrada
          if (self.qrStatus != 4) {
            self.canReloadQR = true;
            self.loading = false;
            self.qrStatus = 3;
          }
          break;
        case 'corrupt':
          console.log("corrupt");
          self.canReloadQR = true;
          self.loading = false;
          self.qrStatus = 2;
          break;
        case 'failure':
          console.log("failure");
          self.canReloadQR = true;
          self.retryQR();
          break;
        default:
          console.log("Invalid status");
          break;
      }
    },
    async processQRCode() {
      var self = this;
      console.log("processQRCode");
      console.log(self.isWhatsBailInbox);
      console.log(!self.isRequestingNewConnection);

      if (self.isWhatsBailInbox && !self.isRequestingNewConnection) {
        self.loading = true;
        var url = process.env.WHATSBAIL_WEB_API + 'callpicker/status';
        if (JSON.parse(self.otherClients).includes(self.accountId + "")) {
          url = process.env.WHATSBAIL_WEB_API + 'callpicker/status';
        }
        try{
          let result_status = await axios.post(url, {key: btoa(self.phone_number)});
          self.loading = false;
          self.loggedQR();

          if(this.settings.is_disconnected){
            this.settings.is_disconnected = false;
            let payload = {
              id: this.currentInboxId,
            };
            payload.settings = JSON.stringify(this.settings);
            await this.$store.dispatch('inboxes/updateInbox', payload);
          }
        }catch(err){
          console.log(err);
          self.loading = false;
          self.canReloadQR = true;
          self.loading = false;
          self.qrStatus = 3;
        }

        return 1;
      }

      if(!self.canReloadQR){
        console.log("Actualmente no puede pedir otro QR.");
        return;
      }

      await self.createChannel();

      setTimeout(function(){
        console.log("registeraccount");

        self.qrStatus = 0;
        self.canReloadQR = false;
        //self.whatsappwebQR = self.loading;
        self.loading = true;
        var data_to_post = {}
        var url = null;

        if (self.isWhatsBailInbox) {
          console.log("isWhatsBailInbox");
          console.log(process.env.WHATSBAIL_WEB_API);
          url = process.env.WHATSBAIL_WEB_API + 'callpicker/register';
          if (JSON.parse(self.otherClients).includes(self.accountId + "")) {
            url = process.env.WHATSBAIL_WEB_API + 'callpicker/register';
          }
          data_to_post = {
            phone: self.phone_number,
            inbox_id: self.inbox.id,
            account_id: self.accountId,
            chat_url: process.env.FRONTEND_URL+"/"
          }
        } else {
          console.log("WhatsAppAPI");
          console.log(process.env.WHATS_APP_WEB_API);
          url = process.env.WHATS_APP_WEB_API + 'registeraccount';
          if (JSON.parse(self.otherClients).includes(self.accountId + "")) {
            url = process.env.WHATS_APP_WEB_API_OTHER + 'registeraccount';
          }
          data_to_post = {
            inbox_id: self.inbox.id,
            isRequestingNewConnection: self.isRequestingNewConnection
          }
        }

        console.log(url);
        console.log(data_to_post);
        axios.post(url, data_to_post)
        .then(function (response) {
          if (!self.isWhatsBailInbox) {
            self.phone_number = response.data.payload.phone_number;
          }
        })
        .catch(function (error) {
          self.canReloadQR = true;
          self.qrStatus = 4;
          self.retryQR();
        });
      }, 500);

    },
    requestNewConnection() {
      this.isRequestingNewConnection = true;
      this.processQRCode();
    },
    retryQR(){
      console.log("retryQR " + this.refresh_wa);
      this.whatsappwebQR = this.refresh_wa;
      this.loading = false;
      console.log("Algo falló");
    },
    printQR(qr){
      var self = this;
      console.log("printQR" + qr);
      //this.whatsappwebQR = this.loading;
      setTimeout(function(){
        self.whatsappwebQR = qr;
        self.loading = false;
        self.qrStatus = 4;
      }, 500);
    },
    loggedQR(){
      //this.whatsappwebQR = this.sucess_wa;
      this.isRequestingNewConnection = false;
      this.qrStatus = 1;
    },
    unclosed(){
      console.log("unclosed");
      if(this.socket){
        socket.emit('unclosed', { phone: this.inbox.id });
      }
    },
    async ML_Loggin(){
      self = this
      self.loading = true;
      self.disconnected = false;
      if(self.timer != null){
        clearTimeout(self.timer)
      }
      self.timeout = false
      self.success_login = false

      var socket_url = process.env.MERCADOLIBRE_APP_WEB_WSS;

      if(self.ioChanelListen == false){
        console.log(self.ioChanelListen)
        self.ioChanelListen = true;
        self.socket = await SocketIO(socket_url, {
          transports: ['websocket']
        });
      }
      var left = (screen.width - 1200) / 2;
      var top = (screen.height - 650) / 4;

      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1200,height=650,left=${left},top=${top}`;

      self.ventana = open(`https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=${process.env.MERCADOLIBRE_CLIENT_ID}`, 'login', params);

      self.timer = setTimeout(function(){
        self.ioChanelListen = false;
        self.timeout = true;
        self.loading = false;
        self.socket.close()
      }, 60000)

      await self.socket.on(`ml_channel:${self.mercado_email}`, (data) => {
        if(data == "create_success"){
          self.success_login = true
          self.timeout = false
          self.ventana.close()
        }
        self.socket.close()
      })
    },
    closeSocketML(){
      if(self.ioChanelListen == true){
        this.socket.emit("client_out",true)
      }
    },
    async checkInbox(){
      let self = this;
      let is_mercado_inbox = this.isMercadoLibreInbox

      if(is_mercado_inbox){
       await axios.get(`${process.env.MERCADOLIBRE_APP_WEB_API}api/mercado_libre/get_inbox_email?inbox_id=${this.inbox.id}`).then(res => {
          self.mercado_email = res.data.email;
          self.disconnected = res.data.isDisconnect;
        })
      }
    },
    redirectInbox(){
      window.location.href = proccess.env.FRONTEND_URL+'/app/accounts/'+this.accountId+'/settings/inboxes/'+this.inbox.settings?.facebook_inbox_id;
    },
    async changeInboxWhatsAppType() {
      if (this.isCallpickerUser) {
        console.log("changeInboxWhatsAppType");
        var self = this;
        this.loading = true;
        if (!this.settings) {
          this.settings = {}
        }
        if (!self.isWhatsBailInbox) {
          console.log("Change to WhatsBailInbox");
          console.log((self.phone_number));
          self.webhookUrl = process.env.WHATSBAIL_WEB_API + 'callpicker/webhook';
          self.settings['type_api'] = "whatsbail";
          self.phone_number = self.phone_number.replace(/^(\d{10})$/, '521$1')
          self.settings['number'] = self.phone_number

          await self.updateInbox()
          self.loading = false;
          self.$router.push({ name: 'settings_inbox_list' });
        } else {
          console.log("WHATS_APP_WEB deprecated");
        }
      } else {
        console.log("Invalid User");
      }
    }
  },
  validations: {
    selectedInboxEmail: {
      required,
      email
    },
    webhookUrl: {
      shouldBeUrl,
    },
    selectedInboxName: {},
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.double-profile-image {
  position: relative;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.space-left {
  margin-left: 12px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;

}

.top-left-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.bottom-right-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  height: 40%;
  border-radius: 50%;
  transform: translate(5%, 5%);
}

.card-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5eaf0 !important;
  border-radius: 8px;
  background-color: #F8FAFC;
}

.card-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.card-content {
  padding: 16px;
  p{
    margin: 0;
  }
}

.card-header {
  justify-content: space-between;
}

.card-title {
  font-weight: bold;
  p{
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.card-subtitle {
  font-size: 14px;
  color: #666;
}

.card-footer {
  margin-top: 12px;
}

.card-label {
  font-size: 12px;
  margin-bottom: 5px;
}
.settings {
  background: $color-white;

  .settings--tabs {
    ::v-deep .tabs {
      padding: 0;
    }
  }

  .settings--content {
    div:last-child {
      border-bottom: 0;
    }
  }

  .tabs {
    padding: 0;
    margin-bottom: -1px;
  }
}

.widget--feature-flag {
  padding-top: var(--space-small);
}

.settings-item {
  padding-bottom: var(--space-normal);

  .help-text {
    font-style: normal;
    color: var(--b-500);
    padding-bottom: var(--space-smaller);
  }
}
</style>
